import { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import './media.css'

function Media() {
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [videosPerPage] = useState(8); // Number of videos per page

  // const fetchVideos = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await fetch(
  //       "https://www.googleapis.com/youtube/v3/search?key=AIzaSyAI0yHl_W2mm7bdZsAxDy4C7ObQDTjj1Ew&channelId=UCJbBnMKMEn90KUABECdI1cA&part=snippet,id&maxResults=50" 
  //     );
  //     const data = await response.json();
  //     if (data.items.length > 0) {
  //       data.items.shift(); // Remove the first item
  //       setVideos(data.items);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching videos:', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const API_KEY = "AIzaSyAI0yHl_W2mm7bdZsAxDy4C7ObQDTjj1Ew";
  const CHANNEL_ID = "UCJbBnMKMEn90KUABECdI1cA";

  const fetchVideos = async () => {
    setIsLoading(true);
    let allVideos = [];
    let nextPageToken = "";

    try {
      do {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&maxResults=100&pageToken=${nextPageToken}`
        );

        const data = await response.json();
        if (data.items) {
          allVideos = [...allVideos, ...data.items];
        }

        nextPageToken = data.nextPageToken || "";

      } while (nextPageToken); // Keep fetching until no more pages

      setVideos(allVideos);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
    setIsLoading(false);
  };

  // Calculate pagination info
  const lastPage = Math.ceil(videos.length / videosPerPage);
  const pagination = {
    current_page: currentPage,
    last_page: lastPage
  };

  // Get current videos based on pagination
  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);

  // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const shareVideo = (videoId) => {
    // Create a shareable link using the videoId
    const shareableLink = `https://www.youtube.com/watch?v=${videoId}?feature=share`;
    window.open(shareableLink, '_blank');
  };

  const downloadVideo = (videoId) => {
    // Create a shareable link using the videoId
    const downloadVideoLink = `https://www.ssyoutube.com/watch?v=${videoId}`;
    window.open(downloadVideoLink, '_blank');
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPages = pagination.last_page;
    const currentPage = pagination.current_page;

    // Always show first page
    pageNumbers.push(
      <li key="first" className={`page-item ${currentPage === 1 ? 'active' : ''}`}>
        <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
      </li>
    );

    // Add ellipsis if there are pages between first and current - 2
    if (currentPage > 3) {
      pageNumbers.push(<li key="ellipsis-start" className="page-item disabled"><button className="page-link">...</button></li>);
    }

    // Show the current page and its surrounding pages
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(currentPage + 1, totalPages - 1); i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(i)}>{i}</button>
        </li>
      );
    }

    // Add ellipsis if there are pages between current + 2 and last
    if (currentPage < totalPages - 2) {
      pageNumbers.push(<li key="ellipsis-end" className="page-item disabled"><button className="page-link">...</button></li>);
    }

    // Always show last page if not already shown
    if (currentPage !== totalPages) {
      pageNumbers.push(
        <li key="last" className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
        </li>
      );
    }

    return pageNumbers;
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner
        text='Loading. Please, wait...'
      ></LoadingOverlay>

      {/* Hero section for sermons */}
      <div className="subpage-head has-margin-bottom sermons-section">
        <div>
          <h3>Sermon</h3>
          <p className="lead">You cannot, but God Can. Rest in His work</p>
        </div>
      </div>
      {/* Hero section for sermons ends here */}

      <section>
        <div className="sermonslist">
          <div className="container">
            <div className="sermonslist-flex">
              {videos.length === 0 ? (
                <div style={{ /* your no videos style */ }}>
                  No Sermon Found.
                </div>
              ) : (
                currentVideos.map((v, i) => (
                  <div key={`media-${indexOfFirstVideo + i}`} className="sermonslist-flex-items">
                    <iframe
                      className='sermonslist-flex-bg'
                      title={`Video ${indexOfFirstVideo + i}`}
                      width="100%"
                      height="200"
                      src={`https://www.youtube.com/embed/${v.id.videoId}`}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                    <div className="sermonslist-flex-details">
                      <p className="sermonslist-flex-details-title sermonslist-flex-details-width">
                        {v.snippet.title}
                      </p>
                      <div className="sermonslist-flex-details-width">
                        <p className="sermonslist-flex-details-date">
                          {v.snippet.publishedAt}
                        </p>
                      </div>
                      {/* <audio src="your_audio_src.mp3" controls /> */}
                      <div className="sermonslist-flex-details-audioinfo sermonslist-flex-details-width" style={{marginTop: '15px'}}>
                        <div className="sermons-audios-icon-properties">
                          <i className="fas fa-download" onClick={() => downloadVideo(v.id.videoId)} style={{ cursor: 'pointer' }}></i>
                          <i className="fas fa-share" onClick={() => shareVideo(v.id.videoId)} style={{ cursor: 'pointer' }} />
                        </div>
                        <p>Church Sermon</p>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-4 mb-3">
          <nav>
            <ul className="pagination">
              <li className={`page-item ${pagination.current_page === 1 ? "disabled" : ""}`}>
                <button
                  className="page-link"
                  onClick={() => handlePageChange(pagination.current_page - 1)}
                  disabled={pagination.current_page === 1}
                >
                  Previous
                </button>
              </li>
              {renderPageNumbers()}
              <li className={`page-item ${pagination.current_page === pagination.last_page ? "disabled" : ""}`}>
                <button
                  className="page-link"
                  onClick={() => handlePageChange(pagination.current_page + 1)}
                  disabled={pagination.current_page === pagination.last_page}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </section>
    </div>
  );
}

export default Media;