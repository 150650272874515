import { useState } from "react";
// import PaystackPop from '@paystack/inline-js'
// import axios from "axios";
// import swal from 'sweetalert';

function Donation() {

    // const [donationAmount, setDonationAmount] = useState("");
    const [copied, setCopied] = useState(false);
    const [copyText, setCopyText] = useState("");

    // const [checkoutInput, setCheckoutInput] = useState({
    //     firstname: '',
    //     lastname: '',
    //     phone: '',
    //     amount: '',
    //     email: '',
    //     address: '',
    //     comment: '',
    //     state: '',
    //     country: ''
    // });


    // const handlePayment = () => {

    //     if (!checkoutInput.firstname || 
    //         !checkoutInput.lastname || 
    //         !checkoutInput.email || 
    //         !donationAmount ||
    //         !checkoutInput.address || 
    //         !checkoutInput.comment || 
    //         !checkoutInput.state || 
    //         !checkoutInput.country 
    //         ) 
    //         {
    //         swal("Oops!", "Kindly complete the required fields before proceeding with your donation.", "error");
    //         return; 
    //     }

    //     const paystackOptions = {
    //         key: "pk_test_0c7f59bdcbbcefc975c3795b13f967c2b41ec104",
    //         email: checkoutInput.email,
    //         amount: parseInt(donationAmount) * 100,
    //         onSuccess(transaction){
    //             let message = `Payment Complete! Reference ${transaction.reference}`
    //             swal("Thank you for Donating to Our Ministry", message, "success");
    //         },

    //         callback: (response) => {
    //             if (response.status === "success") {
    //                 const orderinfo_data = {
    //                     firstname: checkoutInput.firstname,
    //                     lastname: checkoutInput.lastname,
    //                     phone: checkoutInput.phone,
    //                     email: checkoutInput.email,
    //                     address: checkoutInput.address,
    //                     comment: checkoutInput.comment,
    //                     state: checkoutInput.state,
    //                     country: checkoutInput.country,
    //                     amount: donationAmount,
    //                     payment_id: response.reference,
    //                 };

    //                 // Send form data to the backend using Axios
    //                 axios
    //                     .post("http://gdc-api.test/api/donations", orderinfo_data)
    //                     .then((response) => {
    //                         console.log(response.data.message);
    //                         // Additional actions
    //                     })
    //                     .catch((error) => {
    //                         console.error("Error:", error);
    //                     });
    //             }
    //         },
    //     };

    //     try {
    //         const paystack = new PaystackPop();
    //         paystack.newTransaction(paystackOptions);
    //     } catch (error) {
    //         console.error("PaystackPop Error:", error);
    //         swal("Payment Error", "An error occurred during payment. Please fill amount and email field.", "error");
    //     }
    // };


    // const handleInput = (e) => {
    //     e.persist();
    //     setCheckoutInput({ ...checkoutInput, [e.target.name]: e.target.value });
    // }

    const handleCopyClick = (text) => {
        const textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        setCopied(true);
        setCopyText(text);
        setTimeout(() => {
            setCopied(false);
            setCopyText("");
        }, 1000);
    };
    return (
        <>
            <section className="subpage-head has-margin-bottom sermons-section">
                <div className="">
                    <h3>Donate</h3>
                    <p className="lead">Make a Difference. Support our mission. Donate today.</p>
                </div>
            </section>
            {/* <div className="py-4">
                <div className="container">
                    <div className="card-body">

                        <div className="row" style={{ border: '2px dashed #e74c3c', backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '10px' }}>
                            <div className="col-md-7">
                                <form id="phpcontactform" role="form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="">
                                                <div className="form-group mb-3">
                                                    <label> First Name</label>
                                                    <input type="text" name="firstname" onChange={handleInput} value={checkoutInput.firstname} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="form-group mb-3">
                                                    <label> Last Name</label>
                                                    <input type="text" name="lastname" onChange={handleInput} value={checkoutInput.lastname} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="form-group mb-3">
                                                    <label> Phone Number</label>
                                                    <input type="number" name="phone" onChange={handleInput} value={checkoutInput.phone} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="form-group mb-3">
                                                    <label> Email Address</label>
                                                    <input type="email" name="email" onChange={handleInput} value={checkoutInput.email} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="form-group mb-3">
                                                    <label> Amount to Give</label>
                                                    <input
                                                        type="text"
                                                        name="amount"
                                                        onChange={(e) => setDonationAmount(e.target.value)}
                                                        value={donationAmount}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="">
                                                <div className="form-group mb-3">
                                                    <label>State</label>
                                                    <input type="text" name="state" onChange={handleInput} value={checkoutInput.state} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="form-group mb-3">
                                                    <label>Country</label>
                                                    <input type="text" name="country" onChange={handleInput} value={checkoutInput.country} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="form-group mb-3">
                                                    <label> Full Address</label>
                                                    <textarea rows="3" name="address" onChange={handleInput} value={checkoutInput.address} className="form-control"></textarea>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="form-group mb-3">
                                                    <label> Comment</label>
                                                    <textarea rows="3" name="comment" onChange={handleInput} value={checkoutInput.comment} className="form-control"></textarea>
                                                </div>
                                            </div>
                                            <div className="">
                                                <button
                                                    type="button"
                                                    className="btn btn-lg btn-primary mx-1"
                                                    onClick={handlePayment}
                                                >
                                                    Pay Online
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                            <div className="col-md-5 has-margin-bottom contact-church-address">
                                <h5>OUR ADDRESS</h5>
                                <div className="row">
                                    <div className="col-lg-6"> By Shilo Arena Junction Adjacent<br />
                                        AM 2PM Hotel Molipa Express, <br />
                                        Ijebu-Ode, ogun State, Nigeria
                                    </div>
                                    <div className="col-md-6">Phone: +234 7055662406<br />
                                    </div>
                                </div>
                                <hr className="contact-hr" />
                            </div>
                        </div>
                        <div className="bankdetail-button">

                        </div>
                    </div>
                </div>

            </div> */}

            <section className="container">
                <div className="bank-details">
                    <div className="bank-details-box">
                        <p className="bank-details-box-currency bank-details-box-currencynaira">NAIRA (NGN)</p>
                        <p className="bank-details-box-text">
                            <span>Bank Name: <b>First City Monument Bank</b></span>
                            <span className="copy-icon naira" onClick={() => handleCopyClick("First City Monument Bank (FCMB)")}>
                                {copied && copyText === "First City Monument Bank (FCMB)" ? (
                                    <i className="fa-solid fa-check"></i>
                                ) : (
                                    <i className="fa-solid fa-copy"></i>
                                )}
                            </span>
                        </p>
                        <p className="bank-details-box-text">
                            <span>Account Name: <b>GRACE DEFINITION CHURCH</b></span>
                            <span className="copy-icon naira" onClick={() => handleCopyClick("GRACE DEFINITION CHURCH")}>
                                {copied && copyText === "GRACE DEFINITION CHURCH" ? (
                                    <i className="fa-solid fa-check"></i>
                                ) : (
                                    <i className="fa-solid fa-copy"></i>
                                )}
                            </span>
                        </p>
                        <p className="bank-details-box-text">
                            <span>Account Number: <b>3905591010</b> </span>
                            <span className="copy-icon naira" onClick={() => handleCopyClick('3905591010')}>
                                {copied && copyText === "3905591010" ? (
                                    <i className="fa-solid fa-check"></i>
                                ) : (
                                    <i className="fa-solid fa-copy"></i>
                                )}
                            </span>
                        </p>
                    </div>

                    <div className="bank-details-box">
                        <p className="bank-details-box-currency bank-details-box-currencynaira">DOLLAR (USD)</p>
                        <p className="bank-details-box-text">
                            <span>Bank Name: <b>ZENITH BANK</b></span>
                            <span className="copy-icon dollar" onClick={() => handleCopyClick("ZENITH BANK (ZENITH)")}>
                                {copied && copyText === "ZENITH BANK (ZENITH)" ? (
                                    <i className="fa-solid fa-check"></i>
                                ) : (
                                    <i className="fa-solid fa-copy"></i>
                                )}
                            </span>
                        </p>
                        <p className="bank-details-box-text">
                            <span>Account Name: <b>GRACE DEFINITION CHURCH.</b></span>
                            <span className="copy-icon dollar" onClick={() => handleCopyClick("GRACE DEFINTION CHURCH.")}>
                                {copied && copyText === "GRACE DEFINITION CHURCH." ? (
                                    <i className="fa-solid fa-check"></i>
                                ) : (
                                    <i className="fa-solid fa-copy"></i>
                                )}
                            </span>
                        </p>
                        <p className="bank-details-box-text">
                            <span>Account Number: <b>5000040502</b> </span>
                            <span className="copy-icon dollar" onClick={() => handleCopyClick('5000040502')}>
                                {copied && copyText === "5000040502" ? (
                                    <i className="fa-solid fa-check"></i>
                                ) : (
                                    <i className="fa-solid fa-copy"></i>
                                )}
                            </span>
                        </p>
                    </div>

                    <div className="bank-details-box">
                        <p className="bank-details-box-currency bank-details-box-currencynaira">POUNDS (GBP)</p>
                        <p className="bank-details-box-text">
                            <span>Bank Name: <b>ZENITH BANK</b></span>
                            <span className="copy-icon pounds" onClick={() => handleCopyClick("ZENITH BANK")}>
                                {copied && copyText === "ZENITH BANK" ? (
                                    <i className="fa-solid fa-check"></i>
                                ) : (
                                    <i className="fa-solid fa-copy"></i>
                                )}
                            </span>
                        </p>
                        <p className="bank-details-box-text">
                            <span>Account Name: <b>GRACE DEFINITION CHURCH</b></span>
                            <span className="copy-icon pounds" onClick={() => handleCopyClick("Grace Definition Church")}>
                                {copied && copyText === "Grace Definition Church" ? (
                                    <i className="fa-solid fa-check"></i>
                                ) : (
                                    <i className="fa-solid fa-copy"></i>
                                )}
                            </span>
                        </p>
                        <p className="bank-details-box-text">
                            <span>Account Number: <b>5061353168</b> </span>
                            <span className="copy-icon pounds" onClick={() => handleCopyClick('5061353168')}>
                                {copied && copyText === "5061353168" ? (
                                    <i className="fa-solid fa-check"></i>
                                ) : (
                                    <i className="fa-solid fa-copy"></i>
                                )}
                            </span>
                        </p>
                    </div>
                    {/* <div className="bank-details-box">
                        <p className="bank-details-box-currency">DOLLAR (USD)</p>
                        <p className="bank-details-box-text"><span>Bank Name: <b>First City Monument Bank</b></span> <span className="copy-icon dollar"><i className="fa-solid fa-copy"></i></span></p>
                        <p className="bank-details-box-text"><span>Account Name: <b>First City Monument Bank</b></span> <span className="copy-icon dollar"><i className="fa-solid fa-copy"></i></span></p>
                        <p className="bank-details-box-text"><span>Account Number: <b>**********</b> </span><span className="copy-icon dollar"><i className="fa-solid fa-copy"></i></span></p>
                        <p className="bank-details-box-text"><span>Sort Code: <b>******</b></span> <span className="copy-icon dollar"><i className="fa-solid fa-copy"></i></span></p>
                        <p className="bank-details-box-text"><span>Swift Code: <b>*******</b> </span><span className="copy-icon dollar"><i className="fa-solid fa-copy"></i></span></p>
                    </div> */}
                </div>


            </section>
        </>
    )
}

export default Donation
