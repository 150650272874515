const test = true;

var myObj = {
  ministryData: test
    ? [
        {
          id: "1",
          title: "CHILREN UNIT",
          shortText:
            "We're thrilled to invite all the little adventurers to our fun-filled world...",
          fullText:
            "Here, we believe that every child is unique and special...",
        },
        {
          id: "2",
          title: "TECHNICAL/SOUND UNIT",
          shortText:
            "At Grace Definition Church, we understand the power of technology...",
          fullText:
            "Our Technical and Sound Department is dedicated to delivering top-notch audiovisual support...",
        },
      ]
    : [],

  AllPastorPages: test
    ? [
        {
          id: "osas",
          name: "Osas Oluwasanmi",
          image: "/images/Pst.Osas.jpeg",
          logo: "/images/pastors_image/plain-logo.png",
          profile_details: "coming soon...",
        },
        {
          id: "Awesome",
          name: "Aanuoluwapo Awesome",
          image: "/images/pastors_image/pastor-7.jpeg",
          logo: "/images/pastors_image/plain-logo.png",
          profile_details: "coming soon...",
        },
        {
          id: "Gideon",
          name: "Gideon Olatide",
          image: "/images/pastors_image/pastor-2.jpeg",
          logo: "/images/pastors_image/plain-logo.png",
          profile_details: `Rev'd Gideon Olatide has been a passionate follower and disciple of the Lord Jesus Christ since his secondary school days. 
              His commitment in this sphere brings to him various leadership responsibilities, an involvement that continues even beyond his university days.
              His main forte is the spiritual development, growth and maturity of believers.
              He is empathetic and compassionate, readily connecting with other believers in achieving their God-given visions.
              A graduate of English studies from the prestigious Olabisi Onabanjo University, Ogun state, Nigeria, Gideon Olatide is a mentor to many and a teacher of the word with evident leadership qualities.
              He is married to Pastor Sayo and blessed with lovely daughters Zion and Zoe.`,
        },
          {
              id: "Koju",
              name: "Dupe Cudjoe",
              image: "/images/pastors_image/pastor-4.jpeg",
              logo: "/images/pastors_image/plain-logo.png",
              profile_details: `coming soon...`
          },
        //   {
        //       id: "Clememt",
        //       name: "Clement Cudjoe",
        //       image: "/images/pastors_image/pastor-3.jpeg",
        //       logo: "/images/pastors_image/plain-logo.png",
        //       profile_details: `coming soon...`
        //   },
        {
          id: "Babajide",
          name: "Babajide Odeyinka",
          image: "/images/pastors_image/pastor-6.jpeg",
          logo: "/images/pastors_image/plain-logo.png",
          profile_details: `coming soon...`,
        },
        //   {
        //       id: "Essien",
        //       name: "Samuel Essien",
        //       image: "/images/pastors_image/pastor-1.jpeg",
        //       logo: "/images/pastors_image/plain-logo.png",
        //       profile_details: `coming soon...`
        //   },
      ]
    : [],
  pastorsData: test
    ? [
        {
          id: "osas",
          name: "Osas Oluwasanmi",
          image: "/images/Pst.Osas.jpeg",
          logo: "/images/pastors_image/plain-logo.png",
          profile_details: "coming soon...",
        },
        {
          id: "Awesome",
          name: "Aanuoluwapo Awesome",
          image: "/images/pastors_image/Pastor 7.jpg",
          logo: "/images/pastors_image/plain-logo.png",
          profile_details: "coming soon...",
        },
        {
          id: "Gideon",
          name: "Gideon Olatide",
          image: "/images/pastors_image/Pastor 2.jpg",
          logo: "/images/pastors_image/plain-logo.png",
          profile_details: "coming soon...",
        },
        // {
        //     id: "Koju",
        //     name: "Dupe Cudjo",
        //     image: "/images/pastors_image/Pastor 4.jpg",
        //     logo: "/images/pastors_image/plain-logo.png",
        //     profile_details: "coming soon..."
        // },
        // {
        //     id: "Clememt",
        //     name: "Clement Cudjo",
        //     image: "/images/pastors_image/Pastor 3.jpg",
        //     logo: "/images/pastors_image/plain-logo.png",
        //     profile_details: "coming soon..."
        // },
        {
          id: "Babajide",
          name: "Babajide Odeyinka",
          image: "/images/pastors_image/Pastor 6.jpg",
          logo: "/images/pastors_image/plain-logo.png",
          profile_details: "coming soon...",
        },
        // {
        //     id: "Essien",
        //     name: "Samuel Essien",
        //     image: "/images/pastors_image/Pastor 1.jpg",
        //     logo: "/images/pastors_image/plain-logo.png",
        //     profile_details: "coming soon..."
        // },
      ]
    : [],
  blogData: test
    ? [
        {
          title: "We have MOVED",
          body: "“We are assured and know that [God being a partner in their labor] all things work together and are [fitting into a plan] for good to and for those who love God and are called according to [His] design and purpose.” Romans 8:28 MPC….Glory!!!",
          date: "on 13th Aug 2023",
          src: "./images/pj3.jpg",
          preacher: "Pastor James Oluwasanmi",
        },
        {
          title: "Walk by Faith",
          body: "May the Word of God guide your steps and illuminate your path, as you walk in the grace and love of our Lord Jesus Christ. Let His wisdom be your anchor and His promises your source of strength. In the name of Jesus, may your journey be blessed and your heart forever filled with His divine presence....",
          date: "on 13th Aug 2023",
          src: "./images/pj2.jpg",
          preacher: "Pastor James Oluwasanmi",
        },
        {
          title: "Empowered by Faith: I Can Do All Things Through Christ",
          body: "May the words of Philippians 4:13 uplift and guide you: 'I can do all things through Christ who strengthens me.' In the name of Jesus, may this verse inspire you to embrace challenges with unwavering faith, knowing that His strength empowers you to overcome any obstacles in your path. Amen....",
          date: "on 13th Aug 2023",
          src: "./images/pj.jpg",
          preacher: "Pastor James Oluwasanmi",
        },
      ]
    : [],

  sermonList: test
    ? [
        {
          url: "https://gracedefinitionaudio.mixlr.com/recordings/2181308",
          title: "Sunday sermon",
          duration: "03:07:16 hrs",
        },
        {
          url: "https://gracedefinitionaudio.mixlr.com/recordings/2174894",
          title: "Sunday sermon",
          duration: "03:07:16 hrs",
        },
        {
          url: "https://gracedefinitionaudio.mixlr.com/recordings/2168357",
          title: "Sunday sermon",
          duration: "03:07:16 hrs",
        },
        {
          url: "https://gracedefinitionaudio.mixlr.com/recordings/2166974",
          title: "Sunday sermon",
          duration: "03:07:16 hrs",
        },
      ]
    : [],
  upComingEvents: test
    ? [
        {
          title: "2023 Kids Church Camp.",
          body: `One of the primary aims of our church summer camp for children 
            is to foster spiritual growth and understanding. The camp would 
            include Bible studies, worship sessions, prayer times, and 
            discussions about faith and value. The summer camp aims to also 
            deepen the children's faith and help them grow in their relationship with God.`,
          date: "August 2nd - 4th, 2023",
          time: "8:00AM - 5:30PM",
          address: "54, Bonojo beside Blooms pharmacy, Ijebu-Ode",
        },
        {
          title: "2023 Kids Church Camp.",
          body: `One of the primary aims of our church summer camp for children 
            is to foster spiritual growth and understanding. The camp would 
            include Bible studies, worship sessions, prayer times, and 
            discussions about faith and value. The summer camp aims to also 
            deepen the children's faith and help them grow in their relationship with God.`,
          date: "August 2nd - 4th, 2023",
          time: "8:00AM - 5:30PM",
          address: "54, Bonojo beside Blooms pharmacy, Ijebu-Ode",
        },
        {
          title: "2023 Kids Church Camp.",
          body: `One of the primary aims of our church summer camp for children 
            is to foster spiritual growth and understanding. The camp would 
            include Bible studies, worship sessions, prayer times, and 
            discussions about faith and value. The summer camp aims to also 
            deepen the children's faith and help them grow in their relationship with God.`,
          date: "August 2nd - 4th, 2023",
          time: "8:00AM - 5:30PM",
          address: "54, Bonojo beside Blooms pharmacy, Ijebu-Ode",
        },
      ]
    : [],
  gdc: {
    text: " For by grace are ye saved through faith; and that not of yourselves: it is the gift of God.",
    passage: "Eph 2:8 (KJV)",
  },
  liveSermon: test
    ? {
        src: "https://player.vimeo.com/video/72772592?title=0&byline=0&portrait=0&color=3b7794",
        text: `At Grace Definition Church, we believe in the power of God's word to transform lives. Join us for our inspiring live sermons, 
        where we come together as a loving and faith-filled community to grow in our relationship with God and each other. 
        Our sermons are carefully crafted to inspire, uplift, and bring us closer to the divine.`,
        block: `If you can't join us for the live broadcast, don't worry! The sermons will be recorded and available for viewing 
        on our website shortly after each live event. You can also access past sermons to catch up on any messages you may have missed.`,
      }
    : {},
  audioSermon: test
    ? [
        {
          title: "Sermon title",
          preacher: "Pst. James Oluwasanmi",
          date: "JULY 14, 2023",
          program: "Sunday Service",
          src: "",
        },
        {
          title: "Sermon title",
          preacher: "Pst. James Oluwasanmi",
          date: "JULY 14, 2023",
          program: "Sunday Service",
          src: "",
        },
        {
          title: "Sermon title",
          preacher: "Pst. James Oluwasanmi",
          date: "JULY 14, 2023",
          program: "Sunday Service",
          src: "",
        },
      ]
    : [],
  videoSermon: test ? [] : [],
}; 

export default myObj;