import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { appURL } from "../../env";


export default function About() {

  const formatTime = (timeString) => {
    const options = { hour: 'numeric', minute: '2-digit', hour12: true };
    return new Date(`1970-01-01T${timeString}`).toLocaleTimeString([], options);
  };

  // const formatDate = (dateString) => {
  //   const options = { weekday: 'long'};
  //   return new Date(dateString).toLocaleDateString([], options);
  // };

  const [events, SetEvent] = useState(null);
  useEffect(() => {
    axios.get(`${appURL}/admin/event/get_event_for_webpage`, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => {
        // console.log(res.data.data);
        SetEvent(res.data.data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });


  }, []);

  return (
    <>
      {/*About hero section start here*/}
      <div className="subpage-head has-margin-bottom contact-section aboutus-section">
        <div className="container">
          {/* <h3>About us</h3> */}
          {/* <p class="lead">Welcome to Grace Definition Church!</p> */}
        </div>
      </div>
      {/*About hero section ends here*/}



      <div className="container has-margin-bottom">
        <div className="row">
          <div className="col-md-9 has-margin-bottom about-us-details">
            <p className="lead">At Grace Definition Church, we are a community of believers dedicated to living out
              the transformative power of God&lsquo;s grace. Our church family is built on the foundation of God&lsquo;s Word,
              and we strive to create an environment where everyone can encounter the love and truth of Jesus Christ.
            </p>
            <h4>Our Vision:</h4>
            <p>At Grace Definition Church, our vision is to be a vibrant and welcoming church that brings hope, healing,
              and restoration to individuals and families in our community and beyond. We desire to see lives transformed
              by the grace of God and to empower believers to live out their God-given purpose.
            </p>
            <h4>Our Mission: </h4>
            <p>Making a Difference<br />We are dedicated to equipping and empowering believers to grow in their faith and become
              ully devoted disciples of Jesus Christ. Through intentional teaching, mentoring, and small group interactions, we
              provide resources and opportunities for spiritual growth, enabling individuals to live out their faith authentically.
            </p>
            <p>Our mission is to love God passionately, love people unconditionally, and make disciples intentionally. We aim to provide
              a place where people from all walks of life can come together to worship, grow in their faith, and serve others with compassion
              and generosity.
            </p>
            <h4>What We Believe:</h4>
            <p> WHAT WE BELIEF IN:
              As a church we have our conviction as people called of God. The word CHURCH come from the Greek word “ecclesia” which means “the called out ones” we are the people called out of the world to be in Christ.
              Our Creed:
              The bible is God’s word. 2 Tim 3:16
              Jesus Christ is the son of God. Mat 1:18, john 3:16
              Jesus is the way. John 14:16
              The trinity. Gen 1:1-3, john 1:1 and John14:16
              All need to be saved. Rom 3:23
              Jesus Christ died and rose from the dead. 1 Cor 15:4
              The resurrection from the dead. 1 Thess 4:16-17
              Baptism of the Holy Spirit. Mark 16:17-18, acts 1:8
              Jesus Christ is coming again. Mat 28:19, 26:26-29
              FOUR UNCOMPROMISING QUALITIES OF THE FIVE STAR CHURCH
            </p>
            <h4>Core Purpose</h4>
            <p>
              Core Purpose
              Core values – which create and influence the culture
              A structure – strength beyond the presence of one individual
              A relative commitment to EXCELLENCE
            </p>
            <h4>Core Value</h4>
            <p>CORE VALUES – Rom 12:2
              G – Good
              A – Acceptable: - legal (within the boundaries of God’s word)
              P – Perfect: - culture of excellence, going the extra mile, being led by the spirit.</p>
            <h4>Our Worship:</h4>
            <p>At Grace Definition Church, we value heartfelt worship that honors God and engages the whole person. Through uplifting music, prayer,
              and relevant teaching, we seek to create an atmosphere where individuals can connect with God on a deep level and experience His
              presence in a meaningful way.
            </p>
            <h4>Our Ministries:</h4>
            <p>We offer a variety of ministries and program designed to meet the diverse needs of our church family. From dynamic children&lsquo;s and
              youth ministries to engaging small groups and impactful outreach initiatives, there is a place for everyone to belong, grow, and serve.
            </p>
            <h4>Join Us:</h4>
            <p>We invite you to join us for our worship services and experience the warmth and fellowship of our church family. Whether you are
              exploring faith for the first time, seeking spiritual growth, or looking for a community where you can make a difference, you are
              welcome here.
            </p>
            <p>Come as you are and discover the life-transforming grace of Jesus Christ at Grace Definition Church.</p>
          </div>
          <div className="col-md-3 visible-md-block visible-lg-block about-us-upcoming-event">
            <div className="section-title highlight-bg has-padding-xs-top has-padding-bottom about-us-heading-row">
              <h4> UPCOMING EVENTS </h4>
            </div >
            {events && events.map((event, index) => (
              <div key={index} className="el-block has-margin-xs-bottom">
                <h4> {event.date} </h4>
                <p className="el-head">{event.name}</p>
                <span>{event && formatTime(event.start_time)}</span>
                <p className="el-cta">
                  {/* <a className="btn btn-primary" href="#" role="button">Details →</a> */}
                  <Link to={`/event/${event.id}`} className="btn btn-primary">Details &nbsp;&nbsp; &rarr;</Link>
                </p>
              </div>
            ))}

          </div>
        </div>
      </div>

    </>
  );
}