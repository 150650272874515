
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import { appURL } from '../../env';
import axios from 'axios';
// import $ from 'jquery'; // Import jQuery
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const UpcomingEvents = () => {

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    autoplay: true, // Enable autoplay
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      1000: {
        items: 3
      }
    }
  };

  const formatTime = (timeString) => {
    const options = { hour: 'numeric', minute: '2-digit', hour12: true };
    return new Date(`1970-01-01T${timeString}`).toLocaleTimeString([], options);
  };

  const formatDay = (dateString) => {
    const options = { weekday: 'long'};
    return new Date(dateString).toLocaleDateString([], options);
  };
  const formatDate = (dateString) => {
      const date = new Date(dateString);
      const options = { month: 'long', day: 'numeric', year: 'numeric' };
      return date.toLocaleDateString([], options);
  
    // let dayWithOrdinal;
    // if (day === 1 || day === 21 || day === 31) {
    //   dayWithOrdinal = `${day}st`;
    // } else if (day === 2 || day === 22) {
    //   dayWithOrdinal = `${day}nd`;
    // } else if (day === 3 || day === 23) {
    //   dayWithOrdinal = `${day}rd`;
    // } else {
    //   dayWithOrdinal = `${day}th`;
    // }
  
    // return `${date.toLocaleDateString([], options)}, ${dayWithOrdinal}`;
  };
  // function getMonthFromDate(dateString) {
  //   const monthName = new Date(dateString).toLocaleString('default', { month: 'long',  day: 'numeric' });
  //   return monthName;
  // }

  const [events, SetEvent] = useState(null);
  useEffect(() => {
    axios.get(`${appURL}/admin/event/get_event_for_webpage`, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => {
        // console.log(res.data.data);
        SetEvent(res.data.data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });


  }, []);
  return (
    
    <div className="highlight-bg has-margin-bottom">
      <div className="container event-list">
        <div className="section-title">
          <h4> PROGRAMS &amp; EVENTS </h4>
        </div>
        <div className="row">
          <div className="col-md-12">
            { events && events.length &&
            <OwlCarousel className='owl-theme' {...carouselOptions}  >
              {events && events.map((event, index) => (
                <div className="el-block item" key={index}>
                  {/* <img
                      src={events && event.event_image} 
                      alt={`Event ${index + 1}`}
                      className="event-image" 
                    /> */}
                  <h4>{events &&formatDate(event.date)}</h4>
                  {/* <h4>{event.date && getMonthFromDate(event.date)}</h4> */}
                  <p className="el-head">{event.description}</p>
                  <span>
                  {formatDay (event.date)} {formatTime(event.start_time)}
                </span>

                  <p className="el-cta">
                    <Link to={`/event/${event.id}`} className="btn btn-primary">Details &nbsp;&nbsp; &rarr;</Link>
                  </p>
                </div>
              ))}

            </OwlCarousel>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default UpcomingEvents